import React from 'react'

import { useDialogState } from '../../main/components/dialog'
import { TECHNICAL_EXTRA_FILTER_PREFIX } from './filter-modal/compare-indicators/constants'
import { FilterDialog } from './filter-modal/filter-dialog'
import { ConfigType } from './filter-modal/types'
import { useCustomRangeController } from './use-custom-range-controller'
import { useHideCustomControll } from './use-hide-custom-controller'
import { useTickersInputController } from './use-tickers-input-controller'
import {
  ScreenerRenderCustomModal,
  eventListenerRegister,
  getIsOptionDateRange,
  parseScreenerFilters,
  textContent,
} from './utils'

export function FilterDialogRoot() {
  useCustomRangeController()
  useTickersInputController()
  useHideCustomControll()
  const [activeSelectEl, setActiveSelectEl] = React.useState<HTMLSelectElement>()
  const isInitRef = React.useRef(false)
  const dialogState = useDialogState({
    setOpen(open) {
      if (!open) {
        setActiveSelectEl(undefined)
        handleCancel()
      }
    },
  })

  const config = React.useMemo<ConfigType | null>(() => {
    if (!activeSelectEl) {
      return null
    }
    const parentNode = activeSelectEl.parentNode! as HTMLElement
    const filter = activeSelectEl.getAttribute('data-filter')!
    const title = textContent(parentNode.previousElementSibling as HTMLElement)
    const selectedValues = parseScreenerFilters()[filter] ?? []
    const selectedOption = activeSelectEl.value
    const options = Array.from(activeSelectEl.options)
      .map((option) => ({ value: option.value, label: option.text }))
      .filter((opt) => {
        const isModal = opt.value.includes('modal')
        const isEmpty = opt.value === ''
        return !isModal && !isEmpty && !getIsOptionDateRange(opt.value)
      })

    return {
      selectedOption,
      filter,
      title,
      options,
      selectedValues,
    }
  }, [activeSelectEl])

  const handleCancel = React.useCallback(() => {
    const hiddenFsmElement = document.getElementById('fsm_' + config?.filter)
    if (activeSelectEl && (!hiddenFsmElement || hiddenFsmElement.style.display === 'none')) {
      activeSelectEl.value = activeSelectEl.getAttribute('data-selected')!
      activeSelectEl.onchange?.(new Event('change'))
    }
  }, [activeSelectEl, config])

  React.useEffect(() => {
    function ScreenerSelectOnChange(that: HTMLElement) {
      const filter = that.getAttribute('data-filter'),
        query = that.getAttribute('data-url'),
        queryWithFilter = that.getAttribute('data-url-selected'),
        el = document.getElementById('fs_' + filter) as HTMLInputElement,
        value = el.value
      let q = query

      if (!FinvizSettings.hasUserPremium && !filter?.startsWith(TECHNICAL_EXTRA_FILTER_PREFIX)) {
        const eliteUrl = 'elite.ashx?utm_source=finviz&utm_medium=banner&utm_campaign=screener-select-'
        if (value === 'range' || value === 'frange' || value.indexOf('modal') > -1) {
          window.location.href = eliteUrl + 'custom-elite'
          return
        } else if (value === 'more' || value === 'restricted') {
          window.location.href = eliteUrl + value + '-elite'
          return
        }
      }

      if (value === 'range' || value === 'frange') {
        el.style.display = 'none'
        const customRange = document.getElementById('fsr_' + filter) as HTMLElement
        customRange.style.display = 'block'
        return
      } else if (value.includes('modal')) {
        ScreenerShowModal.call(document.getElementById('fs_' + filter))
        return
      } else if (value.length > 0) {
        const searchParams = new URLSearchParams(queryWithFilter!)
        const screenerFilterValue = searchParams.get('f')
        const filtersArray = screenerFilterValue
          ?.split(',')
          .map((item) => {
            if (item === `${filter}_selected_filter`) {
              return value === 'delete' ? '' : filter + '_' + value
            }
            return item
          })
          .filter((item) => item)
        if (filtersArray && filtersArray.length > 0) {
          searchParams.set('f', filtersArray!.join(','))
        }
        q = searchParams.toString()
      }
      window.location.href = 'screener.ashx?' + q
    }

    function ScreenerShowModal(this: any) {
      const filter = this.getAttribute('data-filter')
      const select = document.getElementById('fs_' + filter) as HTMLSelectElement
      setActiveSelectEl(select)
      dialogState.show()
    }

    const edits = Array.from(document.getElementsByClassName('js-custom-modal-edit')) as HTMLElement[]
    const unregisterEventFuncs = [] as Array<() => void>
    for (let i = 0; i < edits.length; i++) {
      const unregister = eventListenerRegister(edits[i], 'click', ScreenerShowModal)
      unregisterEventFuncs.push(unregister)
    }

    if (!isInitRef.current) {
      isInitRef.current = true
      const selects = Array.from(document.querySelectorAll('.filters-cells select, .screener-combo-text.is-selected'))
      for (let i = 0; i < selects.length; i++) {
        const select = selects[i] as HTMLSelectElement
        if (select.value === 'range' || select.value === 'frange') {
          ScreenerSelectOnChange(select)
        } else if (select.value === 'modal') {
          ScreenerRenderCustomModal(select)
        }
      }

      window.ScreenerSelectOnChange = ScreenerSelectOnChange
    }

    return () => {
      unregisterEventFuncs.forEach((unregister) => unregister())
    }
  }, [dialogState])

  return <FilterDialog config={config} dialogState={dialogState} />
}
