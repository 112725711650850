import { PRESET_VALUES_DELIMITER } from './constants'
import config from './precalculate-indicators.json'
import { InstrumentType } from './types'

const availableIndicators = Object.keys(config).map((key) => ({
  id: key as InstrumentType,
  label: key[0].toUpperCase() + key.slice(1),
  items: config[key as keyof typeof config].map((indicator) => ({
    value: indicator.name,
    label: indicator.shortLabel,
  })),
}))

const indicatorOptions = Object.fromEntries(
  (Object.values(config) as Array<(typeof config)[keyof typeof config]>).flatMap((arr) =>
    arr.map((indicator) => [
      indicator.name,
      indicator.optionSets.map((optSet) => ({
        value: optSet.map((item) => item).join(PRESET_VALUES_DELIMITER),
        label: optSet.map((item) => item).join(', '),
      })),
    ])
  )
)

const indicatorCalculatedValues = Object.fromEntries(
  (Object.values(config) as Array<(typeof config)[keyof typeof config]>).flatMap((arr) =>
    arr.map((indicator) => [
      indicator.name,
      indicator.calculatedValues.map((calcVal) => ({
        value: calcVal.toLowerCase(),
        label: (indicator.calculatedValuesLabels as Record<string, string>)[calcVal],
      })),
    ])
  )
)

const defaultCalculatedValues = Object.fromEntries(
  (Object.values(config) as Array<(typeof config)[keyof typeof config]>).flatMap((arr) =>
    arr.map((indicator) => [indicator.name, indicator.defaultCalculatedValueOption.toLowerCase()])
  )
)

export const INDICATORS_CONFIG = Object.freeze({
  availableIndicators,
  indicatorOptions,
  indicatorCalculatedValues,
  defaultCalculatedValues,
})

export type IndicatorsType = typeof availableIndicators
export type IndicatorOptionsType = typeof indicatorOptions
export type IndicatorCalcValsType = typeof indicatorCalculatedValues
export type IndicatorDefaultCalcValsType = typeof defaultCalculatedValues

export type IndicatorKey = IndicatorsType[number]['items'][number]['value']
export type IndicatorOptionKey = IndicatorOptionsType[string][number]['value'] | undefined | null
export type IndicatorCalcValKey = IndicatorCalcValsType[string][number]['value']
export { InstrumentType }
