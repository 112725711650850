import { ConfigType } from '../types'
import { CompareCondition, DEFAULT_CONDITION, ICompareCondition } from './compare-condition'
import { DEFAULT_SOURCE, DEFAULT_TARGET, PARAMS_DELIMITER, VALUE_DELIMITER } from './constants'
import { INDICATORS_CONFIG } from './parse-json-util'
import { CompareTargetValueType, IIndicator, InstrumentType, TimeframesType } from './types'

const indicatorOptions = INDICATORS_CONFIG.availableIndicators.filter(({ id }) => id === InstrumentType.indicators)
const valueOptions = INDICATORS_CONFIG.availableIndicators.filter(({ id }) => id === InstrumentType.value)
const nonIndicatorOptions = INDICATORS_CONFIG.availableIndicators.filter(({ id }) => id !== InstrumentType.indicators)

export function getSecondaryIndicatorOptions(val: IIndicator) {
  const isCompareSourceIndicator = indicatorOptions[0].items.some((item) => item.value === val.indicator)
  let indicators

  if (isCompareSourceIndicator) {
    indicatorOptions[0].items = indicatorOptions[0].items.filter(({ value }) => value === val.indicator)
    indicators = [...valueOptions, ...indicatorOptions]
  } else {
    indicators = nonIndicatorOptions
  }
  return {
    indicators,
    indicatorsOptions: INDICATORS_CONFIG.indicatorOptions,
    indicatorsCalcVals: INDICATORS_CONFIG.indicatorCalculatedValues,
    defaultCalculatedValues: INDICATORS_CONFIG.defaultCalculatedValues,
  }
}

export function getDefaultSelectedSource(config: ConfigType) {
  const compareSource = config.selectedValues[0]?.split(VALUE_DELIMITER)

  if (compareSource && compareSource.length === 4) {
    return {
      indicator: compareSource[0].toLowerCase() as IIndicator['indicator'],
      indicatorPreset: compareSource[1],
      indicatorCalcVal: compareSource[2].toLowerCase() as IIndicator['indicatorCalcVal'],
      timeframe: compareSource[3] as TimeframesType,
    }
  }

  return DEFAULT_SOURCE
}

export function getDefaultSelectedCondition(config: ConfigType) {
  const compareCondition = config.selectedValues[1]?.split(VALUE_DELIMITER)
  const compareTarget = config.selectedValues[2]?.split(VALUE_DELIMITER)
  if (
    config.selectedValues?.length === 3 &&
    compareCondition &&
    compareTarget &&
    compareCondition.length === 3 &&
    compareTarget.length === 4
  ) {
    return {
      condition: compareCondition[0] as ICompareCondition['condition'],
      rangeFrom: compareCondition[1] !== '' ? parseFloat(compareCondition[1]) : null,
      rangeTo: compareCondition[2] !== '' ? parseFloat(compareCondition[2]) : null,
    }
  }

  return DEFAULT_CONDITION
}

export function getDefaultSelectedTarget(config: ConfigType) {
  const compareCondition = config.selectedValues[1]?.split(VALUE_DELIMITER)
  const compareTarget = config.selectedValues[2]?.split(VALUE_DELIMITER)
  if (config.selectedValues?.length === 3 && compareCondition?.length === 3 && compareTarget?.length >= 4) {
    const [targetType, targetPreset, targetCalcVal, timeframeOrValue] = compareTarget
    const numberValue = parseFloat(timeframeOrValue)

    return {
      indicator: (targetType !== '' ? targetType.toLowerCase() : null) as CompareTargetValueType['indicator'],
      indicatorPreset: targetPreset !== '' ? targetPreset : null,
      indicatorCalcVal: (targetCalcVal !== ''
        ? targetCalcVal.toLowerCase()
        : null) as CompareTargetValueType['indicatorCalcVal'],
      timeframe: targetType !== 'value' ? (timeframeOrValue as TimeframesType) : null,
      value: targetType === 'value' && Number.isFinite(numberValue) ? numberValue : null,
    }
  }

  return DEFAULT_TARGET
}

export function getPrimaryIndicatorOptions(filterOnlyIndicator?: string) {
  const indicators = INDICATORS_CONFIG.availableIndicators.filter(
    ({ id }) =>
      id !== InstrumentType.other &&
      id !== InstrumentType.value &&
      (filterOnlyIndicator !== undefined ? id === filterOnlyIndicator : true)
  )
  return {
    indicators,
    indicatorsOptions: INDICATORS_CONFIG.indicatorOptions,
    indicatorsCalcVals: INDICATORS_CONFIG.indicatorCalculatedValues,
    defaultCalculatedValues: INDICATORS_CONFIG.defaultCalculatedValues,
  }
}

export function isFilterValid(selectedCondition: ICompareCondition, selectedTarget: CompareTargetValueType) {
  const isValid = { target: true, condition: true }

  if (
    [CompareCondition.between, CompareCondition.outside].includes(selectedCondition.condition) &&
    selectedCondition.rangeFrom === null &&
    selectedCondition.rangeTo === null
  ) {
    isValid.condition = false
  }
  if (selectedTarget.indicator === 'value' && selectedTarget.value === null) {
    isValid.target = false
  }

  return isValid
}

export function stringifyFilter(
  selectedSource: IIndicator,
  selectedCondition: ICompareCondition,
  selectedTarget: CompareTargetValueType
) {
  const sourceString = [
    selectedSource.indicator,
    selectedSource.indicatorPreset,
    selectedSource.indicatorCalcVal,
    selectedSource.timeframe,
  ].join(VALUE_DELIMITER) // bb.20 2.sma.d === indicatorName.indicatorPresetValue.incidatorCalculatedValue.timeframe
  const conditionString = [
    selectedCondition.condition,
    selectedCondition.rangeFrom ?? '',
    selectedCondition.rangeTo ?? '',
  ].join(VALUE_DELIMITER)
  const targetString =
    selectedTarget.indicator === 'value'
      ? [selectedTarget.indicator, null, null, selectedTarget.value].join(VALUE_DELIMITER)
      : [
          selectedTarget.indicator,
          selectedTarget.indicatorPreset,
          selectedTarget.indicatorCalcVal,
          selectedTarget.timeframe,
        ].join(VALUE_DELIMITER)

  return [sourceString, conditionString, targetString].join(PARAMS_DELIMITER)
}
