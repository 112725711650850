import React from 'react'

import { Select } from '../../../../main/components'
import { ChangeType } from './compare-indicators-component'
import { TIMEFRAMES } from './constants'
import {
  IndicatorCalcValKey,
  IndicatorCalcValsType,
  IndicatorDefaultCalcValsType,
  IndicatorKey,
  IndicatorOptionKey,
  IndicatorOptionsType,
  IndicatorsType,
} from './parse-json-util'
import { IIndicator, TimeframesType } from './types'

interface ICompareSourceProps {
  data: {
    indicators: IndicatorsType
    indicatorsOptions: IndicatorOptionsType
    indicatorsCalcVals: IndicatorCalcValsType
    defaultCalculatedValues: IndicatorDefaultCalcValsType
  }
  handleSelect: (args: { type: ChangeType; value: IIndicator }) => void
  selected: IIndicator
}

export function CompareSource({ data, handleSelect, selected }: ICompareSourceProps) {
  const handleIndicatorSettingsChange = (settingType: string, value: string) => {
    const newValue = { ...selected }

    if (settingType === 'indicator') {
      newValue.indicator = value as IndicatorKey
    }

    if (settingType === 'indicatorPreset') {
      newValue.indicatorPreset = value as IndicatorOptionKey
    } else if (!data.indicatorsOptions[newValue.indicator].some((item) => item.value === newValue.indicatorPreset)) {
      newValue.indicatorPreset = data.indicatorsOptions[newValue.indicator][0]?.value
    }

    if (settingType === 'indicatorCalcVal') {
      newValue.indicatorCalcVal = value as IndicatorCalcValKey
    } else if (!data.indicatorsCalcVals[newValue.indicator].some((item) => item.value === newValue.indicatorCalcVal)) {
      newValue.indicatorCalcVal = data.defaultCalculatedValues[newValue.indicator]
    }

    if (settingType === 'timeframe') {
      newValue.timeframe = value as TimeframesType
    }

    handleSelect({ type: ChangeType.compareSource, value: newValue })
  }

  return (
    <>
      <Select
        data-testid="compare-source-indicator"
        key={`primary_${selected}`}
        className="w-25"
        aria-label="Indicator name"
        value={selected.indicator}
        items={data.indicators}
        onChange={(item) => {
          handleIndicatorSettingsChange('indicator', item.value)
        }}
      />
      {data.indicatorsOptions[selected.indicator].length > 0 && selected.indicatorPreset !== undefined && (
        <Select
          data-testid="compare-source-preset"
          disabled={data.indicatorsOptions[selected.indicator].length === 1}
          key={`primary_${selected.indicatorPreset}`}
          aria-label="Available options"
          items={data.indicatorsOptions[selected.indicator]}
          value={selected.indicatorPreset}
          onChange={(item) => {
            handleIndicatorSettingsChange('indicatorPreset', item.value)
          }}
        />
      )}
      {data.indicatorsCalcVals[selected.indicator].length > 1 && (
        <Select
          data-testid="compare-source-calcval"
          key={`primary_${selected.indicatorCalcVal}`}
          aria-label="Computed value name"
          items={data.indicatorsCalcVals[selected.indicator]}
          value={selected.indicatorCalcVal}
          onChange={(item) => {
            handleIndicatorSettingsChange('indicatorCalcVal', item.value)
          }}
        />
      )}
      <Select
        data-testid="compare-source-timeframe"
        key={`primary_${selected.timeframe}`}
        aria-label="Timeframe"
        items={TIMEFRAMES(window.FinvizSettings.hasUserPremium)}
        value={selected.timeframe}
        onChange={(item) => {
          handleIndicatorSettingsChange('timeframe', item.value)
        }}
      />
    </>
  )
}
