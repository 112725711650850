import React, { useTransition } from 'react'

import { Button } from '../../../../main/components/button'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../../main/components/dialog'
import { encodeQueryString } from '../../../queryString'
import { FilterProps } from '../types'
import { CompareComponent, CompareCondition, ICompareCondition } from './compare-condition'
import { CompareSource } from './compare-source'
import { CompareTarget } from './compare-target'
import { DEFAULT_TARGET, EMPTY_TARGET } from './constants'
import { CompareTargetComponentValueType, CompareTargetValueType, IIndicator, INTRADAY_FREE_TIMEFRAME } from './types'
import {
  getDefaultSelectedCondition,
  getDefaultSelectedSource,
  getDefaultSelectedTarget,
  getPrimaryIndicatorOptions,
  getSecondaryIndicatorOptions,
  isFilterValid,
  stringifyFilter,
} from './utils'

export enum ChangeType {
  'compareSource',
  'compareCondition',
  'compareTarget',
}

export function CompareIndicatorsDialog({ onSubmit, config }: FilterProps) {
  const [isInProgress, startTransition] = useTransition()
  const [selectedSource, setSelectedSource] = React.useState<IIndicator>(() => getDefaultSelectedSource(config))
  const [selectedCondition, setSelectedCondition] = React.useState(() => getDefaultSelectedCondition(config))
  const [selectedTarget, setSelectedTarget] = React.useState<CompareTargetValueType>(() =>
    getDefaultSelectedTarget(config)
  )
  const [conditionError, setConditionError] = React.useState<boolean>()
  const [targetError, setTargetError] = React.useState<boolean>()

  const handleChange = ({
    type,
    value,
  }: {
    type: ChangeType
    value: IIndicator | ICompareCondition | CompareTargetValueType
  }) => {
    startTransition(() => {
      setConditionError(false)
      setTargetError(false)

      switch (type) {
        case ChangeType.compareSource:
          setSelectedSource(value as IIndicator)
          const availableTargets = getSecondaryIndicatorOptions(value as IIndicator)
          if (
            ![CompareCondition.between, CompareCondition.outside].includes(selectedCondition.condition) &&
            !availableTargets.indicators.some(({ items }) =>
              items.some(({ value }) => value === selectedTarget.indicator)
            )
          ) {
            setSelectedTarget(DEFAULT_TARGET)
          }
          break
        case ChangeType.compareCondition:
          setSelectedCondition(value as ICompareCondition)
          if ([CompareCondition.between, CompareCondition.outside].includes((value as ICompareCondition).condition)) {
            setSelectedTarget(EMPTY_TARGET)
          } else if (selectedTarget.indicator === null) {
            setSelectedTarget(DEFAULT_TARGET)
          }
          break
        case ChangeType.compareTarget:
          setSelectedTarget(value as CompareTargetValueType)
          break
        default:
          break
      }
    })
  }

  const handleSubmit = () => {
    const isValid = isFilterValid(selectedCondition, selectedTarget)

    setConditionError(!isValid.condition)
    setTargetError(!isValid.target)

    if (isInProgress || !isValid.condition || !isValid.target) return

    if ([selectedSource.timeframe, selectedTarget.timeframe].includes(INTRADAY_FREE_TIMEFRAME.intraday)) {
      document.location = `/elite.ashx?${encodeQueryString({
        utm_source: 'finviz',
        utm_medium: 'banner',
        utm_campaign: 'screener-custom-indicator-compare-filter-intraday',
      })}`
      return
    }

    const filterString = stringifyFilter(selectedSource, selectedCondition, selectedTarget)
    onSubmit([filterString])
  }

  return (
    <>
      <DialogBody className="relative flex max-w-full flex-col justify-center">
        <div className="flex items-center space-x-2">
          <div data-testid="primary-indicator" className="flex space-x-1">
            <CompareSource
              data={getPrimaryIndicatorOptions()}
              key={`primary_select_${selectedSource}`}
              handleSelect={handleChange}
              selected={selectedSource}
            />
          </div>
          <div
            data-testid="compare-settings"
            className="flex items-center space-x-1 rounded bg-gray-50 p-0.5 dark:bg-gray-600"
          >
            <CompareComponent handleSelect={handleChange} selected={selectedCondition} error={conditionError} />
          </div>
          <div data-testid="secondary-indicator" className="flex space-x-1">
            {selectedTarget.indicator !== null && (
              <CompareTarget
                data={getSecondaryIndicatorOptions(selectedSource)}
                key={`secondary_select_${selectedTarget}`}
                handleSelect={handleChange}
                selected={selectedTarget as CompareTargetComponentValueType}
                error={targetError}
              />
            )}
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <div className="flex w-full justify-end">
          <div className="flex space-x-2">
            <Button as={DialogDismiss} data-testid="screener-custom-indicator-dialog-cancel">
              Cancel
            </Button>
            <Button onClick={handleSubmit} theme="blue" data-testid="screener-custom-indicator-dialog-submit">
              Submit
            </Button>
          </div>
        </div>
      </DialogFooter>
    </>
  )
}
