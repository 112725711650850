import React from 'react'

import { Button } from '../../../../main/components/button'
import { DialogBody, DialogDismiss, DialogFooter } from '../../../../main/components/dialog'
import { encodeQueryString } from '../../../queryString'
import { ICompareCondition } from './compare-condition'
import { ChangeType } from './compare-indicators-component'
import { CompareSource } from './compare-source'
import { VALUE_DELIMITER } from './constants'
import { INDICATORS_CONFIG, IndicatorKey } from './parse-json-util'
import { CompareTargetValueType, EXTRADAY_TIMEFRAMES, IIndicator, INTRADAY_FREE_TIMEFRAME } from './types'
import { getPrimaryIndicatorOptions } from './utils'

function getDefaultByIndicator(indicator: IndicatorKey): IIndicator {
  return {
    indicator,
    indicatorPreset: INDICATORS_CONFIG.indicatorOptions[indicator][0]?.value,
    indicatorCalcVal: INDICATORS_CONFIG.defaultCalculatedValues[indicator],
    timeframe: EXTRADAY_TIMEFRAMES.d,
  }
}

export function SelectSourceDialog({
  data,
  onSubmit,
  selected,
}: {
  data: ReturnType<typeof getPrimaryIndicatorOptions>
  onSubmit: (values: string) => void
  selected: string
}) {
  const [selectedSource, setSelectedSource] = React.useState<IIndicator>(
    getDefaultByIndicator(selected as IndicatorKey)
  )

  const handleChange = ({
    type,
    value,
  }: {
    type: ChangeType
    value: IIndicator | ICompareCondition | CompareTargetValueType
  }) => {
    switch (type) {
      case ChangeType.compareSource:
        setSelectedSource(value as IIndicator)
        break
      default:
        break
    }
  }

  const handleSubmit = () => {
    const sourceString = [
      selectedSource.indicator,
      selectedSource.indicatorPreset,
      selectedSource.indicatorCalcVal,
      selectedSource.timeframe,
    ].join(VALUE_DELIMITER) // bb.20 2.sma.d === indicatorName.indicatorPresetValue.incidatorCalculatedValue.timeframe

    if (selectedSource.timeframe === INTRADAY_FREE_TIMEFRAME.intraday) {
      document.location = `/elite.ashx?${encodeQueryString({
        utm_source: 'finviz',
        utm_medium: 'banner',
        utm_campaign: 'screener-add-filter-intraday',
      })}`
    } else {
      onSubmit(sourceString)
    }
  }

  return (
    <>
      <DialogBody className="relative flex max-w-full flex-col justify-center">
        <div className="flex items-center space-x-2">
          <div data-testid="primary-indicator" className="grid grid-cols-4 gap-1">
            <CompareSource
              data={data}
              key={`primary_select_${selectedSource}`}
              handleSelect={handleChange}
              selected={selectedSource}
            />
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <div className="flex w-full justify-end">
          <div className="flex space-x-2">
            <Button data-testid="screener-add-filter-select-source-cancel-btn" as={DialogDismiss}>
              Cancel
            </Button>
            <Button data-testid="screener-add-filter-select-source-apply-btn" onClick={handleSubmit} theme="blue">
              Apply
            </Button>
          </div>
        </div>
      </DialogFooter>
    </>
  )
}
