import classNames from 'classnames'
import React from 'react'

import { IconNameType, Select, TooltipInput } from '../../../../main/components'
import { ChangeType } from './compare-indicators-component'

export enum CompareCondition {
  above = 'abv',
  aboveEqual = 'abveq',
  below = 'blw',
  belowEqual = 'blweq',
  crosses = 'cro',
  crossesUp = 'croup',
  crossesDown = 'crodwn',
  between = 'btwn',
  outside = 'out',
  equal = 'eq',
  abovePercent = 'abvpct',
  belowPercent = 'blwpct',
}

const COMPARE_CONDITIONS: { value: CompareCondition; label: string; icon?: IconNameType }[] = [
  { value: CompareCondition.above, label: 'Above', icon: 'above' },
  { value: CompareCondition.aboveEqual, label: 'Above or Equal', icon: 'aboveEqual' },
  { value: CompareCondition.below, label: 'Below', icon: 'below' },
  { value: CompareCondition.belowEqual, label: 'Below or Equal', icon: 'belowEqual' },
  { value: CompareCondition.crosses, label: 'Crosses', icon: 'crosses' },
  { value: CompareCondition.crossesUp, label: 'Crosses Up', icon: 'crossesUp' },
  { value: CompareCondition.crossesDown, label: 'Crosses Down', icon: 'crossesDown' },
  { value: CompareCondition.between, label: 'Between', icon: 'between' },
  { value: CompareCondition.outside, label: 'Outside', icon: 'outside' },
  { value: CompareCondition.equal, label: 'Equal', icon: 'equal' },
  { value: CompareCondition.abovePercent, label: 'Above %', icon: 'abovePercent' },
  { value: CompareCondition.belowPercent, label: 'Below %', icon: 'belowPercent' },
]

enum CompareConditionSettings {
  condition,
  rangeFrom,
  rangeTo,
}

export interface ICompareCondition {
  condition: CompareCondition
  rangeFrom: null | number
  rangeTo: null | number
}

export const DEFAULT_CONDITION: ICompareCondition = {
  condition: CompareCondition.aboveEqual,
  rangeFrom: null,
  rangeTo: null,
}

function getHasConditionRangeInput(value: CompareCondition) {
  return [
    CompareCondition.abovePercent,
    CompareCondition.belowPercent,
    CompareCondition.between,
    CompareCondition.outside,
  ].includes(value)
}

export function CompareComponent({
  handleSelect,
  selected,
  error,
}: {
  handleSelect: (args: { type: ChangeType; value: ICompareCondition }) => void
  selected: ICompareCondition
  error?: boolean
}) {
  const handleCompareConditionSettingsChange = (settingType: CompareConditionSettings, value: string) => {
    const newValue = { ...selected }

    if (settingType === CompareConditionSettings.condition) {
      newValue.condition = value as CompareCondition
      if (!getHasConditionRangeInput(newValue.condition)) {
        newValue.rangeFrom = null
        newValue.rangeTo = null
      }
    }

    if (settingType === CompareConditionSettings.rangeFrom) {
      newValue.rangeFrom = !value ? null : parseFloat(value)
    }

    if (settingType === CompareConditionSettings.rangeTo) {
      newValue.rangeTo = !value ? null : parseFloat(value)
    }

    handleSelect({ type: ChangeType.compareCondition, value: newValue })
  }

  return (
    <>
      <Select
        data-testid="compare-condition-condition"
        aria-label="Compare Condition"
        items={COMPARE_CONDITIONS}
        value={selected.condition}
        leftContent={COMPARE_CONDITIONS.find((item) => item.value === selected.condition)?.icon}
        onChange={(item) => handleCompareConditionSettingsChange(CompareConditionSettings.condition, item.value)}
      />
      {getHasConditionRangeInput(selected.condition) && (
        <>
          <TooltipInput
            data-testid="compare-condition-from"
            type="number"
            placeholder="From"
            value={selected.rangeFrom ?? ''}
            step={0.1}
            onChange={(e) => {
              handleCompareConditionSettingsChange(CompareConditionSettings.rangeFrom, e.currentTarget.value)
            }}
            error={error}
            className={classNames('w-20', { 'is-error': error })}
            tooltipSettings={{ color: 'red' }}
            autoFocus={error}
          >
            {error && 'At least one value must be set.'}
          </TooltipInput>
          <span>-</span>
          <TooltipInput
            data-testid="compare-condition-to"
            type="number"
            placeholder="To"
            value={selected.rangeTo ?? ''}
            min={0}
            step={0.1}
            onChange={(e) => {
              handleCompareConditionSettingsChange(CompareConditionSettings.rangeTo, e.currentTarget.value)
            }}
            error={error}
            className={classNames('w-20', { 'is-error': error })}
            tooltipSettings={{ color: 'red' }}
            autoFocus={error}
          >
            {error && 'At least one value must be set.'}
          </TooltipInput>
        </>
      )}
    </>
  )
}
