import classNames from 'classnames'
import React from 'react'

import { Select, TooltipInput } from '../../../../main/components'
import { ChangeType } from './compare-indicators-component'
import { TIMEFRAMES } from './constants'
import {
  IndicatorCalcValKey,
  IndicatorCalcValsType,
  IndicatorDefaultCalcValsType,
  IndicatorKey,
  IndicatorOptionKey,
  IndicatorOptionsType,
  IndicatorsType,
} from './parse-json-util'
import { CompareTargetComponentValueType, EXTRADAY_TIMEFRAMES, TimeframesType } from './types'

interface ICompareTargetProps {
  data: {
    indicators: IndicatorsType
    indicatorsOptions: IndicatorOptionsType
    indicatorsCalcVals: IndicatorCalcValsType
    defaultCalculatedValues: IndicatorDefaultCalcValsType
  }
  handleSelect: (args: { type: ChangeType; value: CompareTargetComponentValueType }) => void
  selected: CompareTargetComponentValueType
  error?: boolean
}

export function CompareTarget({ data, handleSelect, selected, error }: ICompareTargetProps) {
  const handleTargetSettingsChange = (settingType: string, value: string) => {
    const newValue = { ...selected }

    if (settingType === 'indicator') {
      newValue.indicator = value as IndicatorKey
    }

    if (settingType === 'indicatorPreset') {
      newValue.indicatorPreset = value as IndicatorOptionKey
    } else if (!data.indicatorsOptions[newValue.indicator]?.some((item) => item.value === newValue.indicatorPreset)) {
      newValue.indicatorPreset = data.indicatorsOptions[newValue.indicator][0]?.value
    }

    if (settingType === 'indicatorCalcVal') {
      newValue.indicatorCalcVal = value as IndicatorCalcValKey
    } else if (!data.indicatorsCalcVals[newValue.indicator]?.some((item) => item.value === newValue.indicatorCalcVal)) {
      newValue.indicatorCalcVal = data.defaultCalculatedValues[newValue.indicator]
    }

    if (settingType === 'timeframe') {
      newValue.timeframe = value as TimeframesType
    } else if (newValue.indicator !== 'value' && !newValue.timeframe) {
      newValue.timeframe = EXTRADAY_TIMEFRAMES.d
    } else if (newValue.indicator === 'value') {
      newValue.timeframe = null
    }

    if (settingType === 'value') {
      newValue.value = !value ? null : parseFloat(value)
    } else if (newValue.indicator !== 'value') {
      newValue.value = null
    }

    handleSelect({ type: ChangeType.compareTarget, value: newValue })
  }

  return (
    <>
      <Select
        data-testid="compare-target-indicator"
        key={`secondary_${selected}`}
        aria-label="Indicator name"
        value={selected.indicator}
        items={data.indicators}
        onChange={(item) => {
          handleTargetSettingsChange('indicator', item.value)
        }}
      />
      {data.indicatorsOptions[selected.indicator].length > 0 && selected.indicatorPreset !== undefined && (
        <Select
          data-testid="compare-target-preset"
          disabled={data.indicatorsOptions[selected.indicator].length === 1}
          key={`secondary_${selected.indicatorPreset}`}
          aria-label="Available options"
          items={data.indicatorsOptions[selected.indicator]}
          value={selected.indicatorPreset}
          onChange={(item) => {
            handleTargetSettingsChange('indicatorPreset', item.value)
          }}
        />
      )}
      {data.indicatorsCalcVals[selected.indicator].length > 1 && (
        <Select
          data-testid="compare-target-calcval"
          key={`secondary_${selected.indicatorCalcVal}`}
          aria-label="Computed value name"
          items={data.indicatorsCalcVals[selected.indicator]}
          value={selected.indicatorCalcVal}
          onChange={(item) => {
            handleTargetSettingsChange('indicatorCalcVal', item.value)
          }}
        />
      )}
      {selected.indicator !== 'value' ? (
        <Select
          data-testid="compare-target-timeframe"
          key={`secondary_${selected.timeframe}`}
          aria-label="Timeframe"
          items={TIMEFRAMES(window.FinvizSettings.hasUserPremium)}
          value={selected.timeframe}
          onChange={(item) => {
            handleTargetSettingsChange('timeframe', item.value)
          }}
        />
      ) : (
        <TooltipInput
          data-testid="compare-target-value"
          type="number"
          placeholder="Value"
          value={selected.value ?? ''}
          step={0.1}
          onChange={(e) => {
            handleTargetSettingsChange('value', e.currentTarget.value)
          }}
          error={error}
          className={classNames('w-20', { 'is-error': error })}
          tooltipSettings={{ color: 'red' }}
          autoFocus={error}
        >
          {error ? 'Value must be a number' : undefined}
        </TooltipInput>
      )}
    </>
  )
}
