import {
  type CompareTargetValueType,
  EXTRADAY_TIMEFRAMES,
  type IIndicator,
  INTRADAY_FREE_TIMEFRAME,
  INTRADAY_TIMEFRAMES,
} from './types'

export const TIMEFRAMES = (isPremium: boolean) => [
  ...(isPremium
    ? [
        { value: INTRADAY_TIMEFRAMES.i1, label: '1 Minute' },
        { value: INTRADAY_TIMEFRAMES.i2, label: '2 Minutes' },
        { value: INTRADAY_TIMEFRAMES.i3, label: '3 Minutes' },
        { value: INTRADAY_TIMEFRAMES.i5, label: '5 Minutes' },
        { value: INTRADAY_TIMEFRAMES.i10, label: '10 Minutes' },
        { value: INTRADAY_TIMEFRAMES.i15, label: '15 Minutes' },
        { value: INTRADAY_TIMEFRAMES.i30, label: '30 Minutes' },
        { value: INTRADAY_TIMEFRAMES.h, label: '1 Hour' },
        { value: INTRADAY_TIMEFRAMES.h2, label: '2 Hours' },
        { value: INTRADAY_TIMEFRAMES.h4, label: '4 Hours' },
      ]
    : [
        {
          value: INTRADAY_FREE_TIMEFRAME.intraday,
          label: 'Intraday',
          subtitle: 'FINVIZ*Elite only',
          href: `/elite.ashx?utm_source=finviz&utm_medium=banner&utm_campaign=screener-${TECHNICAL_EXTRA_FILTER_PREFIX}-intraday`,
        },
      ]),
  { value: EXTRADAY_TIMEFRAMES.d, label: '1 Day' },
  { value: EXTRADAY_TIMEFRAMES.w, label: '1 Week' },
  { value: EXTRADAY_TIMEFRAMES.m, label: '1 Month' },
]

export const PARAMS_DELIMITER = '|'
export const VALUE_DELIMITER = ':'
export const PRESET_VALUES_DELIMITER = ' '

export const TECHNICAL_EXTRA_FILTER_PREFIX = 'taxi'

export const DEFAULT_SOURCE: IIndicator = {
  indicator: 'sma',
  indicatorPreset: '20',
  indicatorCalcVal: 'sma',
  timeframe: EXTRADAY_TIMEFRAMES.d,
}

export const EMPTY_TARGET: CompareTargetValueType = {
  indicator: null,
  indicatorPreset: null,
  indicatorCalcVal: null,
  timeframe: null,
  value: null,
}

export const DEFAULT_TARGET: CompareTargetValueType = {
  ...EMPTY_TARGET,
  indicator: 'value',
  indicatorPreset: undefined,
  indicatorCalcVal: 'value',
}
