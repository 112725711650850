import React from 'react'

import { Button } from '../../main/components'
import { Dialog, DialogHeader, useDialogState } from '../../main/components/dialog'
import { Select } from '../../main/components/select'
import { decodeQueryString, encodeQueryString } from '../queryString'
import { TECHNICAL_EXTRA_FILTER_PREFIX } from './filter-modal/compare-indicators/constants'
import { SelectSourceDialog } from './filter-modal/compare-indicators/select-source'
import { getPrimaryIndicatorOptions } from './filter-modal/compare-indicators/utils'
import { parseScreenerFilters, stringifyScreenerFilters } from './utils'

const INIT_DATA = getPrimaryIndicatorOptions()
const LIST_PROPS = { className: 'w-25' }

export function ScreenerAddFilter() {
  const [selected, setSelected] = React.useState('')
  const dialogState = useDialogState()

  const handleSubmit = (item: string) => {
    const urlQueryObject = decodeQueryString()

    let nextTaxiIndex = 0
    if (urlQueryObject.f) {
      const lastTaxiIndex = urlQueryObject.f
        .split(',')
        .filter((item) => item.startsWith(TECHNICAL_EXTRA_FILTER_PREFIX))
        .map((item) => Number.parseInt(item.split('_')[1]))
        .sort((a, b) => b - a)[0]
      nextTaxiIndex = lastTaxiIndex !== undefined ? lastTaxiIndex + 1 : 0
    }

    const filters = {
      ...parseScreenerFilters(),
      [`${TECHNICAL_EXTRA_FILTER_PREFIX}_${nextTaxiIndex}`]: [item],
    }

    window.location.href =
      'screener.ashx?' + encodeQueryString({ ...urlQueryObject, f: stringifyScreenerFilters(filters) })
  }

  React.useEffect(() => {
    if (selected) {
      dialogState.show()
    }
  }, [selected, dialogState])

  return (
    <>
      <Select
        data-testid="screener-technical-add-filter"
        nativeSelect="mobile"
        id="screener_ft_ta_addfilter"
        placement="bottom-start"
        items={INIT_DATA.indicators}
        listProps={LIST_PROPS}
        trigger={
          FinvizSettings.hasRedesignEnabled ? (
            <Button data-testid="screener-technical-add-filter-btn" type="button" size="small" theme="light">
              + Filter
            </Button>
          ) : (
            <button data-testid="screener-technical-add-filter-btn" className="screener-combo-button">
              + Filter
            </button>
          )
        }
        value={selected}
        onChange={(item) => setSelected(item.value)}
      />
      {selected && (
        <Dialog
          aria-label="Indicator Settings"
          state={dialogState}
          onFullyClosed={() => setSelected('')}
          data-testid="screener-add-filter-dialog"
        >
          <DialogHeader className="text-sm">Indicator Settings</DialogHeader>
          <SelectSourceDialog data={INIT_DATA} selected={selected} onSubmit={handleSubmit} />
        </Dialog>
      )}
    </>
  )
}
