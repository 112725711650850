import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { ready } from '../shared/ready'
import { ScreenerAddFilter } from './add-filter'
import { FilterDialogRoot } from './filter-dialog-root'
import { ScreenerTimeframeBar } from './screener-timeframe-bar'

const SCREENER_TIMEFRAME_REACT_ROOT_ID = 'screener-timeframe-container'

let timeframeRoot: ReactDOM.Root
const screenerTimeframeReactMount = () => {
  const timeframeContainer = document.getElementById(SCREENER_TIMEFRAME_REACT_ROOT_ID)
  if (timeframeContainer) {
    timeframeRoot = ReactDOM.createRoot(timeframeContainer)

    timeframeRoot.render(
      <BrowserRouter>
        <ScreenerTimeframeBar />
      </BrowserRouter>
    )
  }
}

const screenerTimeframeReactUnmount = () => {
  timeframeRoot?.unmount()
}

ready(() => {
  const screener = document.getElementById('screener-react')
  if (screener) {
    const root = ReactDOM.createRoot(screener)

    root.render(<FilterDialogRoot />)
  }
  screenerTimeframeReactMount()

  const screenerAddFilter = document.getElementById('screener_filter_addfilter_root')
  if (screenerAddFilter) {
    const root = ReactDOM.createRoot(screenerAddFilter)
    root.render(<ScreenerAddFilter />)
  }
})

window.ScreenerTimeframeReactMount = screenerTimeframeReactMount
window.ScreenerTimeframeReactUnmount = screenerTimeframeReactUnmount
